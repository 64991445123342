import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import styles from "../../SingleBilling.module.css";
import { getFilterStatusOptions } from "../../../../../../core/config";
import { getCurrencyName, getCurrencySymbol, roundSum } from "../../../../../../core/helpers";
import InputRadio from "../../../../../ui/input/InputRadio/InputRadio";
import { useDispatch, useSelector } from "react-redux";
import InputFileMulti from "../../../../../ui/input/inputFileMulti/InputFileMulti";
import BillingDocument from "./billingDocument";
import { uploadFile } from "../../../../../../actions/billing";
import { addModal, removeModal } from "../../../../../../reducers/appReducer";
import ContentLoader from "../../../../../ui/contentLoader/ContentLoader";
import BillingInvoice from "./BillingInvoice";

const BillingModalVerification = lazy(() => import("../../../../../modals/billingModalVerification/BillingModalVerification"));

const GeneralTab = ({ billing, setLoading, timer }) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState();
    const [nds, setNds] = useState(billing.nds !== undefined ? billing.nds : null);
    const currencyRates = useSelector(state => state.billing.currencyRatesData);
    const billingStatusObj = getFilterStatusOptions(
        billing.history[0]?.error ? billing.history[0]?.message : "",
        styles.StatusTooltip
    ).find(status => status.value === billing.status);
    const billingCurrency = billing.convertCurrency ?? billing.currency;
    const billingAmount = billing.convertAmount ?? billing.amount;

    const billingCurrencySymbol = billing.convertCurrency
        ? getCurrencySymbol(billing.convertCurrency)
        : getCurrencySymbol(billing.currency) ? getCurrencySymbol(billing.currency) : '';

    const resultPayment = billing.billingType !== 'credit' && billing.partner.revenue && (billing.status === 1 || billing.status === -1)
        ? calculateResultPayment()
        : false;

    const transactionHash = getTransactionHash(billing);

    const commentRef = useRef(null);
    const [activeHover, setActiveHover] = useState(false);

    function getTransactionHash (billing) {
        if (billing.paySystem !== 12) return false;

        if (billing.transactionHash) {
            return billing.transactionHash;
        }

        let hash = '';
        const children = billing.children ?? false;

        if (children && children.length > 0) {
            for (const child of children) {
                if (child.transactionHash) {
                    hash = hash ? hash += `,\n${child.affiseId}: ${child.transactionHash}` : `${child.affiseId}: ${child.transactionHash}`;
                }
            }
        }
        return hash;
    }

    function calculateResultPayment () {
        let paymentAmount, calcString, convertRate;

        if (billingCurrency === billing.partner.currency) {
            paymentAmount = billingAmount + billing.partner.revenue;
            calcString = String(billingAmount).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')
                + (billing.partner.revenue < 0 ? " - " : " + ")
                + String(Math.abs(billing.partner.revenue)).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');

        } else {
            convertRate = getConvertRate();
            const convertedPartnerRevenue = billing.partner.revenue * convertRate;

            if (billingAmount + convertedPartnerRevenue >= 0) {
                paymentAmount = billingAmount + convertedPartnerRevenue;
                calcString = billingAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')
                    + (convertedPartnerRevenue < 0 ? " - " : " + ") + Math.abs(convertedPartnerRevenue).toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');
            } else {
                const billingConvertedAmount = billingAmount / convertRate;
                paymentAmount = billingConvertedAmount + billing.partner.revenue;
                calcString = billingConvertedAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')
                    + (billing.partner.revenue < 0 ? " - " : " + ") + Math.abs(billing.partner.revenue).toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');
            }

        }

        if (paymentAmount >= 0) {
            return {
                total: paymentAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 '),
                payment: paymentAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 '),
                revenue: 0,
                string: calcString,
                convertRate: convertRate
            };
        } else {
            return {
                total: paymentAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 '),
                payment: 0,
                revenue: paymentAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 '),
                string: calcString,
                convertRate: convertRate
            }
        }
    }

    function getConvertRate () {
        let rate = currencyRates.find(item => item.fromId === billing.partner.currency && item.toId === billingCurrency);
        if (!rate) {
            rate = currencyRates.find(item => item.fromId === billingCurrency && item.toId === billing.partner.currency);
        }

        return ((rate.type === 1) ? +(1 / rate.rate) : rate.rate).toFixed(4);
    }

    function setPayAgentOptions () {
        switch (billing.payAgent) {
            case 1:
                return 'Capitalist';
            case 2:
                return 'QIWI';
            case 3:
                return 'Расчетный счет';
            default:
                return 'N/A';
        }
    }

    useEffect(() => {
        if (files && files.length > 0) {
            setLoading(true);
            const uploadFiles = async () => {
                const res = await dispatch(uploadFile(files, billing.id));
                if (res === 'verify') {
                    dispatch(addModal({
                        id: 'billingModalVerification',
                        size: 'big',
                        children:
                            <Suspense fallback={<ContentLoader />}>
                                {BillingModalVerification &&
                                    <BillingModalVerification
                                        params={{ files: files, billingId: billing.id, nds: nds }}
                                        type='billingDocumentUpload'
                                        onError={() => {
                                            dispatch(removeModal('billingModalVerification'))
                                        }}
                                    />}
                            </Suspense>
                    }));
                }
            };
            uploadFiles().then(() => {
                setLoading(false);
                setFiles(null);
            });
        }
        // eslint-disable-next-line
    }, [dispatch, files, billing.id]);

    const getRateString = (billing, taxed = false) => {
        let rate = billing.convertRate;
        if (taxed) {
            if (billing.convertRateTaxed) {
                rate = billing.convertRateTaxed;
            } else {
                const taxPercentage = 3; // TODO: get from config
                const tax = (100 - taxPercentage) / 100;
                rate = rate/tax;
            }
        }

        if (!rate) {
            return '';
        }

        if (billing.amount > billing.convertAmount) {
            rate = 1/rate;
        }

        const fromCurrency = getCurrencyName(billing.currency);
        const toCurrency = getCurrencyName(billing.convertCurrency);

        return `1 ${fromCurrency} = ${roundSum(rate, 4)} ${toCurrency}`;
    }

    return (
        <div className={styles.TabContainer}>
            {resultPayment &&
                <div className={styles.ResultPaymentParams}>
                    <div className={styles.ResultPaymentAmount}>
                        Итоговая сумма платежа:&nbsp; {`${resultPayment.payment} ${getCurrencySymbol(billingCurrency)}`}
                    </div>
                    {resultPayment.convertRate &&
                        <div className={styles.ResultPaymentCalc}>Курс конвертации баланса
                            клиента: {`${resultPayment.convertRate} ${getCurrencySymbol(billingCurrency)} / ${getCurrencySymbol(billing.partner.currency)}`} </div>
                    }
                    <div className={styles.ResultPaymentCalc}>
                        {`( ${resultPayment.string} ) = ${resultPayment.total}  =>  Платеж: ${resultPayment.payment} ${getCurrencySymbol(billingCurrency)}, остаток долга: ${resultPayment.revenue} ${getCurrencySymbol(billing.partner.currency)}`}
                    </div>
                </div>
            }
            <div className={styles.InfoBlock}>
                <div className="row">
                    <div className="col-5">
                        <label className={`${styles.Label} mb-1`}>Платежная система</label>
                        <div className={styles.PayAgent}>{setPayAgentOptions()}</div>
                    </div>
                    <div className="col-7">
                        <label className={styles.Label}>Способ выплаты</label>
                        <label className={styles.LabelCapitalist}>{`Capitalist ID: ${billing?.transactionId || ''}`}</label>
                        <div className={styles.PaySystem}>{billing.paySystemName}</div>
                    </div>
                </div>
            </div>

            {billing.payAgent === 3 &&
                <div className="mb-4">
                    {(billing.invoice && billing.status === 2) &&
                        <BillingInvoice invoice={billing.invoice} />
                    }
                    <div className={`${styles.InfoBlock} ${styles.PaySystemBlock}`}>
                        <div className="row">
                            <div className="col-5 d-flex align-items-center">
                                <label className={styles.Label}>Платежное поручение</label>
                            </div>
                            <div className="col-7">
                                <div className="row">
                                    <div className="col-6">
                                        {((billing.status === 1 || billing.status === -1) && !billing.archive && !billing.partner.archive) &&
                                            <div className={styles.NdsRadio}>
                                                <InputRadio check={nds === true} id='nds1' label='C НДС' name='nds' value={true}
                                                    setValue={val => setNds(val)} />
                                                <InputRadio check={nds === false} id='nds2' label='Без НДС' name='nds'
                                                    value={false} setValue={val => setNds(val)} />
                                            </div>
                                        }
                                    </div>


                                    <div className="col-6 d-flex justify-content-end " style={{position:'relative'}}>
                                        <div style={{ width: 'fit-content' }} onMouseEnter={() => setActiveHover(true)} onMouseLeave={() => setActiveHover(false)} >
                                            <div ref={commentRef} className={styles.tooltip_file_button} style={{
                                                visibility: timer && billing?.partner?.stopped && activeHover ? 'visible' : 'hidden'
                                            }}>Запрет вывода средств</div>
                                            <div style={{ opacity: billing?.partner?.stopped ? 0.3 : 1, pointerEvents: billing?.partner?.stopped ? 'none' : 'all' }} >
                                                <InputFileMulti
                                                    className={styles.LoadFileBtn}
                                                    type='file'
                                                    label={'Загрузить '}
                                                    value={files}
                                                    setValue={val => setFiles(val)}
                                                    multiple={true}
                                                    inactive={{
                                                        value: nds === null,
                                                        message: 'Поле НДС / Без НДС является обязятельным'
                                                    }}
                                                />
                                            </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {billing.document.length > 0 && billing.document.map(doc =>
                        <BillingDocument
                            billingId={billing.id}
                            doc={doc}
                            key={doc._id}
                            setLoading={setLoading}
                        />
                    )}
                </div>
            }
            {transactionHash &&
                <div className={styles.InfoBlock}>
                    <div className="row">
                        <div className="col-5">
                            <label className={styles.Label}>Хеш транзакции</label>
                        </div>
                        <div className={`${styles.Hash} col-7`}>
                            {transactionHash}
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-5">
                    <div className={styles.InfoBlock}>
                        <div className="row">
                            <div className="col-6">
                                <label className={styles.Label}>Статус выплаты</label>
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-end">
                                {billingStatusObj.label}
                            </div>
                        </div>
                    </div>
                    <div className={styles.InfoBlock}>
                        <div className="row">
                            <div className="col-6">
                                <label className={styles.Label}>Комиссия</label>
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-end">
                                {billing.tax
                                    ? `${billing.tax} ${billingCurrencySymbol}`
                                    : billing.status === 2 ? 0 : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-7">
                    <div className={styles.InfoBlock}>
                        <div className="row">
                            <div className="col-5">
                                <label className={styles.Label}>Курс валюты</label>
                            </div>
                            <div className={`${styles.conTitle} col-7 d-flex align-items-center`}>
                                {getRateString(billing)}
                            </div>
                        </div>
                    </div>
                    <div className={styles.InfoBlock}>
                        <div className="row">
                            <div className="col-5">
                                <label className={styles.Label}>Курс конвертации</label>
                            </div>
                            <div className={`${styles.conTitle} col-7 d-flex align-items-center`}>
                                {getRateString(billing, true)}
                            </div>
                        </div>
                    </div>
                    <div className={styles.InfoBlock}>
                        <div className="row">
                            <div className="col-5">
                                <label className={styles.Label}>Примечание</label>
                            </div>
                            <div className={`${styles.conTitle} col-7 d-flex align-items-center`}>
                                {billing.notes}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralTab;
