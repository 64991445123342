import React, {lazy, Suspense, useEffect, useState} from 'react';
import commonCl from "../../SingleBilling.module.css";
import styles from "./ConvertTab.module.css";
import {BillingCurrency, FilterStatusOptions, OptionsCurrencyFromMethodPay} from "../../../../../../core/config";
import iconAlert from "../../../../../../assets/img/icon-alert-circle.svg";
import MutiplayIcon from "../../../../../ui/icons/MutiplayIcon";
import SelectUi from "../../../../../ui/select/SelectUi";
import EqualIcon from "../../../../../ui/icons/EqualIcon";
import {useDispatch, useSelector} from "react-redux";
import {convertCurrencyBilling} from "../../../../../../actions/billing";
import {addModal, removeModal} from "../../../../../../reducers/appReducer";
import ContentLoader from "../../../../../ui/contentLoader/ContentLoader";
import {formatCurrencyAmount, getRateSource, roundSum} from "../../../../../../core/helpers";
import {createNotification} from '../../../../../../actions/app';

const BillingModalVerification = lazy(() => import("../../../../../modals/billingModalVerification/BillingModalVerification"));

const ConvertTab = ({billing, setLoading, setTimer, setBillingConvertCurrency}) => {
    const dispatch = useDispatch();
    const currencyRates = useSelector(state => state.billing.currencyRatesData);
    const statusWait = FilterStatusOptions.find(status => status.value === 1).label;
    const statusReject = FilterStatusOptions.find(status => status.value === -1).label;
    const statusPending = FilterStatusOptions.find(status => status.value === 5).label;
    const billingCurrencySymbol = BillingCurrency.find(item => item.value === billing.currency).name;
    const optionsCurrency = OptionsCurrencyFromMethodPay[billing.paySystem] || [];
    const defaultOption = optionsCurrency.find(item => item.value === (billing.convertCurrency || 'EUR')) || optionsCurrency[0];

    const [convertRate, setConvertRate] = useState({rate: 1});
    const [convertCurrency, setConvertCurrency] = useState(defaultOption?.value || '');
    const [convertCurrencySymbol, setConvertCurrencySymbol] = useState(defaultOption?.label || '');

    useEffect(() => {
        setConvertCurrencySymbol(optionsCurrency.find(item => item.value === convertCurrency)?.label);
        // eslint-disable-next-line
    }, [convertCurrency]);

    useEffect(() => {
        let rate = {rate: 1};
        if (currencyRates.length && convertCurrency !== billing.currency) {
            rate = getConvertRate();
        }
        setConvertRate(rate);
        setBillingConvertCurrency(convertCurrency);
        // eslint-disable-next-line
    }, [convertCurrency, currencyRates]);

    function getConvertRate() {
        let rateObj = currencyRates.find(item => item.fromId === billing.currency && item.toId === convertCurrency);

        let rate = rateObj.rate;
        if (rateObj.type === 1) {
            rate = 1 / rate;
        }

        let result = {...rateObj};

        result.rate = roundSum(rate, 4);

        return result;
    }

    const getCurrentAmount = () => {
        let convertSum;
        if (convertRate) {
            const convertTaxPercentage = 3; // TODO: get from config
            const tax = (100 - convertTaxPercentage) / 100;

            convertSum = (billing.amount * tax) / convertRate.rate;
        }

        return roundSum(convertSum, 2);
    }

    const getRateString = () => {

        let rate = roundSum(1 / convertRate.rate, 4);

        if (billingCurrencySymbol !== convertCurrencySymbol) {
            const convertTaxPercentage = 3; // TODO: get from config
            const tax = (100 - convertTaxPercentage) / 100;

            rate = rate * tax;
        }

        return `1 ${billingCurrencySymbol} = ${roundSum(rate, 4)} ${convertCurrencySymbol}`;
    }

    const makeConversion = async () => {
        if (convertCurrency === billing.currency) {
            dispatch(createNotification({
                type: 'error',
                children: "Саму в себя валюту конвертировать нельзя."
            }));
            return;
        }
        setLoading(true);
        const convertParams = {id: billing.id, convertCurrency: convertCurrency};
        const response = await dispatch(convertCurrencyBilling(convertParams));
        if (response === true) {
            dispatch(addModal({
                id: 'billingModalVerification',
                size: 'big',
                children:
                    <Suspense fallback={<ContentLoader/>}>
                        {BillingModalVerification &&
                            <BillingModalVerification
                                billing={billing}
                                params={convertParams}
                                type='billingConvertCurrency'
                                onError={() => {
                                    dispatch(removeModal('billingModalVerification'))
                                }}
                            />}
                    </Suspense>
            }));
            // dispatch(setVerification(false));
            // setTime(billing.convertDate)
            setTimer(true);
        }
        setLoading(false);
    }

    return (
        <div className={commonCl.TabContainer}>
            <div className={styles.ConvertContainer}>
                <div className={styles.ConvertHeader}>
                    Конвертация валюты
                    <span className={styles.Rate}>
                        {convertRate && convertRate.source ? ` по курсу ${getRateSource(convertRate.source)}` : ''}
                    </span>
                </div>
                {![-1, 1, 5].includes(billing.status) || billing.billingType === 'credit'
                    ?
                    (<div className={styles.ConvertInfoWrapper}>
                        <div className="d-flex align-items-center">
                            <img src={iconAlert} alt="alert"/>
                            <div className="ml-3">
                                <div className="d-flex align-items-baseline">
                                    Конвертация возможна только в статусах:&nbsp;&nbsp;
                                    {statusWait}&nbsp;&nbsp;{statusReject}&nbsp;&nbsp;{statusPending}
                                </div>
                                <div className="mt-1">Так же конвертация запрещена для авансов.</div>
                            </div>
                        </div>
                    </div>)
                    :
                    (<div className={styles.ConvertBlock}>
                        <div className="row">
                            <div className="col-4 pr-0">
                                <label className="d-block mb-2">Сумма биллинга</label>
                                <div className="d-flex align-items-center">
                                    <div className={styles.ConvertField}>
                                        <span>
                                            {billing.amount.toFixed(2)
                                                .replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')}
                                        </span>
                                        <span className={commonCl.Gray}>{billingCurrencySymbol}</span>
                                    </div>
                                    <span className="mx-3"><MutiplayIcon/></span>
                                </div>
                                <label className={`${styles.ConvertRate} d-block mt-3`}>
                                    {getRateString()}
                                </label>
                            </div>
                            <div className={`col-4 pl-0`}>
                                <label className="d-block mb-2">Валюта</label>
                                <div className={styles.CurrencySelect}>
                                    <SelectUi options={optionsCurrency} defaultValue={defaultOption} onChange={(e) => {
                                        setConvertCurrency(e)
                                    }}/>
                                    <span className="mx-3"><EqualIcon/></span>
                                    <span className={styles.ConvertedAmount}>
                                        <label>К оплате</label>

                                        {convertCurrency === billing.currency
                                            ? formatCurrencyAmount(billing.amount, billing.currency)
                                            : formatCurrencyAmount(getCurrentAmount(), convertCurrency)
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className={styles.convertBtnBlock}>
                                    <button className={styles.ConvertBtn} onClick={makeConversion}>Конвертировать
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    );
};

export default ConvertTab;
