import {BillingCurrency, CountryList, OptionsCurrencyFromMethodPay} from "./config";
import {createNotification} from "../actions/app";

export const MODULE_TYPE = {
  USERS:"users",
  BILLING:"billing",
  CONVERSIONS:"conversions",
  PARTNERS:"partners",
  OFFERS:"offers",
  ADVERTISERS:"advertisers",
  OFFERS_PAYMENTS:"offersPayments",
  KNOWLEDGE:"knowledge",
  REPORTS: "reports",
  CREDITS: "credits_advanced",
};

export const getModuleRule = (rules, module) => {
    if(rules) {
        let rule = rules.find(rule => rule.name === module);
        if (rule && parseInt(rule.value) <= 3 && parseInt(rule.value) >= 1) {
            return rule.value;
        }
    }
    return false;
};

export const isEdit = (rule) => {
    return parseInt(rule) === 1;
};

export const isRead = (rule) => {
    return parseInt(rule) <= 2;
};
export const isNumber = (x, y) => {
    if (Number.isInteger(y / x)) {
        return true;
    }
    return false;
};

export const getCurrencySymbol = (currentCurrency) => {
    if (currentCurrency) {
        let result = BillingCurrency.find(currency=>currency.value === currentCurrency);
        if(result){
            return result.symbol;
        }
    }
    return false;
}

export const getCompanyName = (payAcc) => {
    if (payAcc && payAcc.includes('CompanyName')) {
        let preparedData = payAcc.split('CompanyName:');
        let companyName = preparedData[1].split('PayAccount:')[0];
        if(companyName){
            return companyName;
        }
    }
    return '';
}

export const getCurrencyNameSymbol = (currentCurrency) => {
    if(currentCurrency){
        currentCurrency =  currentCurrency.toUpperCase();
        let result = BillingCurrency.find(currency=>currency.name === currentCurrency);
        if(result){
            return result.symbol;
        }
        return false;
    }
}
export const getCurrencyName = (currentCurrency) => {
    if(currentCurrency){
        let result = BillingCurrency.find(currency=>currency.value === currentCurrency);
        if(result){
            return result.name;
        }
        return false;
    }
}

export const getRequisitesPartner = (partner,paySystem,payAcc) => {
    if(!paySystem || !partner.paymentSystems){
        return false;
    }
    let requisites = partner.paymentSystems.filter(paymentSystem => paymentSystem.system_id === paySystem);
    if( requisites.length  === 1){
        requisites = requisites[0];
    }else if(requisites.length > 1 && payAcc){

        requisites = requisites.find((requisite)=> {

            let req = Object.values(requisite.fields);
            if(req){
                return  req.find(re=>re === payAcc);
            }else{
                return  ''
            }

        });

    }
    const type = typeof requisites;
    if(type  === 'array'){
        requisites = requisites[0];
    }


  if(requisites && requisites.fields){

      return  Object.values(requisites.fields).join(" ");
   }
   return false;
}

export const filtersFormatParams = (filters)=>{
    if(filters){
        let fil = [];
          filters.forEach(filter=>{
            if(filter.value!==false){
                 fil.push( `${filter.name}=${filter.value}`);
            }
        });
        return fil.join('&');

    }
}
export const getFilterParam =(filters,field)=>{
    const result = filters.find(filter => filter.name === field ? filter.value : false);
    if(result){
        return result.value;
    }
    return false;
}
export const getOptions =(filters,field)=>{
    const result = filters.find(filter => filter.value === field ? filter.value : false);
    if(result){
        return result.name;
    }
    return false;
}
export const getOptionsLabel =(filters,field)=>{
    if(!field){
        return  false;
    }
    const result = filters.find(filter => filter.value === field ? filter.value : false);
    if(result){
        return result.name ? result.name : result.label;
    }
    return false;
}
export const setFilterParam =( filters, field, value ) => {
    let result = ''
    if (value && Array.isArray(filters)) {
       result = filters?.find( filter => filter.name === field
           ?  filter.value = Array.isArray( value ) && value.length === 0 ? -2 : value
           : false
       );
    } else {
        result = { name:field,value: value };
    }
    if( result ) {
        return result;
    }

}
export const bySortFieldASC = (field)=> {
    return (a, b) => a[field] > b[field] ? 1 : -1;
}
export const bySortFieldDESC = (field)=> {
    const collator = new Intl.Collator('en');
    return    (a, b) =>  collator.compare( b[field],a[field]);
}
export const uniqueCurrency = (data,key) => {
    if (data.length !== 0) {

        const currency = data.map(item => {
            return item[key]
        });
        return [...new Set(currency)];
    }
    return false;
}
export const countyName = (country) => {
    if (country) {
        let elem = CountryList.find(item=>item.alpha2 === country.toLowerCase());
        return elem.name ;
    }
    return false;
}
export const uniqueBill = (data,noUniq) => {

    if (data.length !== 0) {
     let arr = [];
        data.forEach(item => {
            arr.push(noUniq.find(un => un.value === item));
        });
        return  arr;
    }
    return false;
}
export const round30min = (val) => {
    let timeArr = val.split(':');

    if ( timeArr[1] > '00' && timeArr[1] <= "03" ) {
        timeArr[1] = '30'
    } else {
        timeArr[1] = '00'
    }
    return timeArr.join(':');
}
export const processError = ( dispatch, error ) => {
    if (error.response) {
        const response = error.response;
        if (response.status === 403) {
            dispatch(createNotification({
                type: 'error',
                children: response.data.message
            }));
        } else {
            const msgText = error.response.data.message ? error.response.data.message : 'Что-то пошло не так!';
            dispatch( createNotification({
                type: 'error',
                children: msgText
            }) );
        }
    } else {
        dispatch(createNotification({
            type: 'error',
            children: error.request ? 'Ошибка подключения' : 'Непредвиденная ошибка'
        }));
    }
}

export const formatAmountByTriplets = ( amount ) => {
    if (amount /*|| amount === 0*/) {
        return amount.toFixed(2).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1,');
    } else {
        return '-';
    }
}

export const getRateSource = ( source ) => {
    let sourceName = '';
    switch (source) {
        case 'cbrf':
            sourceName = 'ЦБ РФ';
            break;
        case 'cbkz':
            sourceName = 'ЦБ Казахстан';
            break;
        case 'cbuae':
            sourceName = 'ЦБ ОАЭ';
            break;
        case 'capitalist':
            sourceName = 'Capitalist';
            break;
        default:
            sourceName = '';    
    }
    return sourceName;
}

export const formatCurrencyAmount = ( amount, currency ) => {
    const currencySymbol = getCurrencySymbol(currency);
    const formatted =  amount.toFixed(2)
        .replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');

    return currencySymbol ? formatted + ` ${currencySymbol}` : formatted;
}

export const formatPayAccData = ( payAccountString ) => {
    const requisites = {};
    const requisitesArr = payAccountString?.split('\n');

    if ( requisitesArr && requisitesArr.length <= 1 ) {
        return payAccountString;
    }

    if (requisitesArr && requisitesArr.length) {
        for ( const item of requisitesArr ) {
            const index = item.indexOf(':');
            requisites[ item.substring(0, index) ] = item.substring(index + 1);
        }
    }
    return requisites;
}

/**
 * Возвращает true если конвертация для оплаты биллинга не требуется и false если конвертация необходима
 * @param billing
 * @returns {boolean}
 */
export const isSuitCurrency = ( billing ) => {
    const allowCurrencies = OptionsCurrencyFromMethodPay[billing.paySystem]
        ? OptionsCurrencyFromMethodPay[billing.paySystem]
        : [];

    if ( allowCurrencies.find( item => item.value === billing.currency ) ) {
        return true;
    } else {
        return !!allowCurrencies.find(item => item.value === billing.convertCurrency);
    }
}

/**
 * @param date {Date}
 * @returns {Date} at UTC Timezone.
 * Нужна для того, чтобы при использовании даты в формате строки YYYY-MM-DD всегда выводилась та дата,
 * которую выбрали в DatePicker. Иначе начинает съезжать на предыдущее число.
 */
export const shiftDateToUTC = ( date ) => {
    let hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;
    let minutesDiff = (date.getHours() - date.getTimezoneOffset()) % 60;
    date.setHours(hoursDiff);
    date.setMinutes(minutesDiff);
    return date;
}

/**
 * @param date {Date}
 * @returns {string} in form of YYYY-MM-DD
 */
export const getDateString = (date) => {
    return date.toISOString().split('T')[0];
}

/**
 * @param date
 * @returns {Date} first day of date argument month
 */
export const getFirstDayOfMonth = (date) => {
    return new Date( new Date(date).getFullYear(), new Date(date).getMonth(), 1);
}
/**
 * @param date {Date}
 * @returns {Date} last day of date argument month
 */
export const getLastDayOfMonth = ( date ) => {
    return new Date( new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0);
}

/**
 * Устанавливает дату так, чтоб с текущим месяцем получился период в 12 месяцев
 * @returns {Date}
 */
export const setYearAgoDate = () => {
    const today = new Date();
    return new Date( today.setMonth( today.getMonth() - 11));
}


export const getJanuaryOfPreviousYear = () => {
  const today = new Date();
  today.setMonth(0);
  today.setFullYear(today.getFullYear());

  return today;
};

/**
 * Устанавливает дату так, чтоб с текущим месяцем получился период в 4 месяца
 * @returns {Date}
 */
export const setThreeMonthsAgoDate = () => {
    const today = new Date();
    return new Date( today.setMonth( today.getMonth() - 3));
};

/**
 * Устанавливает дату так, чтоб с текущим месяцем получился период в 1 месяц
 * @returns {Date}
 */
export const setOneMonthAgoDate = () => {
    const today = new Date();
    return new Date( today.setMonth( today.getMonth() - 1));
};

export const generateYearMonthMatrix = (startDate, endDate) => {
    const start = new Date( new Date(startDate).getFullYear(), new Date(startDate).getMonth(), 1);
    const end = new Date( new Date(endDate).getFullYear(), new Date(endDate).getMonth() + 1, 0);

    const dateObj = {};

    const startYear = start.getFullYear();
    dateObj[startYear] = {};

    let currentDate = start;
    let currentDateObj = dateObj[startYear];
    currentDateObj[start.getMonth() + 1] = {};

    while ( true ) {
        currentDate.setMonth( currentDate.getMonth() + 1 );

        if ( currentDate > end ) break;

        const year = currentDate.getFullYear();
        if ( dateObj[year] === undefined ) {
            dateObj[year] = {};
            currentDateObj = dateObj[year];
        }

        currentDateObj[currentDate.getMonth() + 1] = {};
    }

    return dateObj;
};

export const getMonthsArr = (dateMatrix) => {
    const monthsArr = [];
    for (const year in dateMatrix) {
        for (const month in dateMatrix[year]) {
            monthsArr.push({year, month});
        }
    }
    return monthsArr;
}

export const roundSum = (sum, depth = 2) => {
    const pow = Math.pow(10, depth);

    return +(Math.round(sum * pow) / pow).toFixed(depth);
}